import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from '../components/seo';

const Indywidualna = () => {
    return (
        <Layout>
            <Seo title="Oferta Pilates w duecie"/>
            <section className="subof-container">
                <section className="subof-intro">
                    <StaticImage 
                        src='../images/main_second.jpg'
                        alt="Maszyny typu Spine Corrector" 
                        loading="eager"
                        quality={90}
                        className='subof-intro_picture'
                    />
                    <h1 className="subof-intro_title">Duety</h1>
                    <h2 className="subof-intro-subtitle">Zajęcia w prywatnych duetach na pilatesowych maszynach oraz na macie z małym sprzętem.</h2>
                </section>
                <section className="subof-hero">
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text bold">Duety</p>
                        <p className="subof-hero_text">Jeśli interesuje Cię zindywidualizowana praca z ciałem, ale czas na treningu chciałbyś/chciałabyś spędzić w miły sposób z bliską Ci osobą i zadbać również o jej zdrowie i formę, zapraszamy na zajęcia Pilates w prywatnych duetach.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Zaczynamy zawsze od sesji diagnostycznej każdej z osób. Na podstawie kwestionariusza historii zdrowia, stylu życia i aktywności fizycznej robimy pogłębiony wywiad a później przeprowadzamy analizę postawy ciała oraz wykonujemy podstawowe testy fizjoterapeutyczne, żeby zobaczyć jak dane ciało pracuje, w jakiej jest formie, jakie są ewentualne przeciążenia, zakresy ruchów, jaka jest świadomość ciała i równowaga.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">W oparciu o diagnostykę ustalamy cele treningów dla każdej z osób podsumowując wszystkie nasze ustalenia na Kartach Klienta. Ustalamy pasujące Wam terminy na wspólne treningi.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Treningi przeprowadzane są na macie z małym sprzętem oraz na maszynach Pilatesowych typu: Reformer with Tower, Cadillac, Ladder Barrel, Wunda Chair, Combo Chair i Spine Corrector.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Rekomendujemy zawsze zajęcia regularne: raz lub dwa razy w tygodniu. Jest to czas zdrowia i powrotu do świetnej formy fizycznej dla każdej z osób oraz czas spędzony w miłej atmosferze z wybraną przez Ciebie bliską osobą.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text bold">Pozostałe rodzaje treningów</p>
                    </div>
                    <p className="subof-hero_text">Osoby zainteresowane pracą z ciałem w duetach lub minigrupach zapraszamy do kontaktu w celu ustalenia charakteru zajęć spośród metod:</p>
                    <ul className="subof-hero_list">
                        <li>Pilates</li>
                        <li>Stretching</li>
                        <li>Trening oddechowy i relaksacyjny</li>
                        <li>Trening mięśni dna miednicy dla kobiet</li>
                        <li>Zajęcia „Świadomość ciała w ruchu”</li>
                        <li>Trening równowagi, propriocepcji i podzielności uwagi w pracy z ciałem</li>
                        <li>Trening neuroplastyczności</li>
                        <li>Trening mięśni oczu</li>
                        <li>Trening dłoni i nadgarstków (np. dla osób dużo pracujących przy komputerze, ale i dla muzyków i artystów, rzemieślników)</li>
                        <li>Trening stóp i stawów skokowych</li>
                        <li>Treningi uzupełniające do treningów sportowych</li>
                    </ul>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Zapraszamy również do zapoznania się z kalendarzem naszych otwartych zajęć oraz wyjątkowych warsztatów i spotkań w naszym Studio.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Aktualności dotyczące naszych zajęć grupowych i warsztatów są zawsze dostępne na naszym profilu na <span className="subof-hero_text-underscore"><a href="https://www.facebook.com/pilatesterapeutyczny/events/?ref=page_internal" target="_blank" className="inline-link">Facebooku</a></span></p>
                    </div>
                    <div className="sub-hero_link-container">
                        <Link to="/zapisy" className="sub-hero_link-link">ZAPISZ SIĘ</Link>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default Indywidualna;